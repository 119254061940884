
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import "~primeflex/primeflex.css";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$monitoramento-web-primary: mat.define-palette(mat.$indigo-palette);
$monitoramento-web-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$monitoramento-web-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$monitoramento-web-theme: mat.define-light-theme((
  color: (
    primary: $monitoramento-web-primary,
    accent: $monitoramento-web-accent,
    warn: $monitoramento-web-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($monitoramento-web-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.erro-snackbar{
  >div{
    background-color: rgb(250, 84, 84 ) !important ;
  }
  *{
    color: #fff;
  }
}


.sucess-snackbar{
  *{
    color: #fff;
  }
}

/***
  Não remover, largura google maps
**/
.map-container {
  width: unset !important;
}

.container-title {
  font-size: 20px;
  font-weight: 600;
  color: #435b8f;
  padding: 20px 0px;
}

.main-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;

  .main-container-content {
    background-color: #F2F2F2;
    height: 100%;
  }
  .app-card {
    background-color: white;
    padding: .5em;
  }
}

.menu-lateral {
  padding: unset !important;

  .mat-expansion-panel {
    border-radius: unset !important;
  }
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #C1BCC2 #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
  width: 12px;
  }

  *::-webkit-scrollbar-track {
  background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
  background-color: #C1BCC2;
  border-radius: 10px;
  border: 3px solid #ffffff;
  }

  .mdc-notched-outline__notch {
    // max-width: 250px !important;
    width: auto !important;
   }

   .campo-medio {
    @include mat.all-component-densities(-2);
  }

  .card-monitorar{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .mat-form-field-no-margin-bottom {
    margin-bottom: -1.25em !important;
  }